.nav-bar {
  display: none;
  z-index: 10;
}

.hamburger-menu {
  background-color: #345eab;
  display: block;
  height: 60px;
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .h1-pad {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0;
    margin-top: 0;
  }

  .p-pad {
    padding-left: 30px;
    padding-right: 30px;
  }

  .h4-pad {
    padding-left: 15px;
    padding-right: 15px;
  }

  h1 {
    font-size: 2.7em !important;
  }

  .foot-logo {
    float: none;
    width: 220px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .foot a {
    text-align: center;
  }
  .foot p {
    text-align: center;
  }
  .foot-div-logo {
    float: none !important;
  }

  .hide {
    display: none;
  }

  .link-icon {
    float: none;
    font-size: 40px;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .bread-margin {
    margin-top: 1.6em;
  }

  .stack-Txt {
    font-size: 3em;
    font-weight: 600;
  }

  .head-img {
    width: 100%;
    height: 200px !important;
  }
  .lead {
    font-size: 1.1em !important;
  }
  .btn-edit {
    margin-top: 30px !important;
  }
  .col-swap {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7493%)
      hue-rotate(1deg) brightness(106%) contrast(100%);
  }
}

// Extra small devices (portrait phones, more than 576px)
@media (min-width: 575.98px) {
  .h1-pad {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0;
    margin-top: 0;
  }

  .p-pad {
    padding-left: 30px;
    padding-right: 30px;
  }

  .h4-pad {
    padding-left: 15px;
    padding-right: 15px;
  }

  h1 {
    font-size: 2.7em !important;
  }

  .foot a {
    text-align: center;
  }

  .hide {
    display: none;
  }

  .foot-logo {
    float: none;
    width: 320px;
    height: auto;
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .link-icon {
    float: none;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
  }
  .bread-margin {
    margin-top: 1.6em;
  }

  .stack-Txt {
    font-size: 4em;
    font-weight: 600;
  }

  .head-img {
    width: 100%;
    height: 260px !important;
  }
  .lead {
    font-size: 1.1em !important;
  }
  .btn-edit {
    margin-top: 30px !important;
  }
  .col-swap {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7493%)
      hue-rotate(1deg) brightness(106%) contrast(100%);
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  // .nav-bar {
  //   display: block;
  // }

  // .hamburger-menu {
  //   display: none;
  // }
  .foot-logo {
    float: none;
    width: 220px;
    height: auto;
    padding: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-edit {
    margin-top: 30px !important;
  }
  .stack-Txt {
    font-size: 6em;
    font-weight: 600;
  }
  .h1-pad {
    padding-top: 0;
    margin-top: 0;
  }
  .col-swap {
    filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7493%)
      hue-rotate(1deg) brightness(106%) contrast(100%);
  }
}

// large devices (tablets, 992px and up)
@media (min-width: 992px) {
  .nav-bar {
    display: block;
  }

  .hamburger-menu {
    display: none;
  }
  .btn-edit {
    margin-top: 0 !important;
  }
  .head-img {
    width: 100%;
    height: auto !important;
  }
  .col-swap {
    filter: brightness(0) saturate(100%) invert(31%) sepia(27%) saturate(3526%)
      hue-rotate(198deg) brightness(88%) contrast(77%);
  }
  .h1-pad {
    padding-top: 90px;
  }
}
